// Aplicación
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

// Páginas
import InicioView from './InicioView.vue';
import ServiciosView from './ServiciosView.vue';
import ComoFuncionaView from './ComoFuncionaView.vue';
import FAQSView from './FAQSView.vue';
import ContactoView from './ContactoView.vue';
import CitaView from './CitaView.vue';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import {f  } from '@fortawesome/free-regular-svg-icons';
import { faBars, faXmark, faCarBattery, faKey, faCarSide, faPlus, faToolbox, faHandshake, faMedal, faEnvelope, faPhone, faCalendarDays, faCaretDown, faCaretUp, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
library.add(
    faBars, faXmark, faCarBattery, faKey, faCarSide, faPlus, faToolbox, faHandshake, faMedal, faEnvelope, faPhone, faCalendarDays, faCaretDown, faCaretUp, faUser, faWrench, faWhatsapp
);

// Rutas
const routes = [
    { path: '/', component: InicioView },
    { path: '/servicios', component: ServiciosView },
    { path: '/como_funciona', component: ComoFuncionaView },
    { path: '/faqs', component: FAQSView },
    { path: '/contacto', component: ContactoView },
    { path: '/cita', component: CitaView },
]
const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach(() => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

// Creación Aplicación
createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');