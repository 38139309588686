<script>
import "@/assets/css/main.css";
import NavComponent from "@/components/NavComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
    name: "App",
    components: { NavComponent, FooterComponent },
};
</script>

<template>
    <NavComponent />
    <div id="app">
        <router-view />
    </div>
    <FooterComponent />
</template>