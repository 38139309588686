<script>
import LogoComponent from '@/components/LogoComponent.vue';

export default {
    name: "FooterComponent",
    data() {
        return {
            year: new Date().getFullYear()
        };
    },
    methods: {
        to(path) {
            if (path == '/' && this.$route.path == '/') {
                window.scrollTo(0, 0);
            }
            this.$router.push(path);
        },
    },
    components: { LogoComponent }
};
</script>

<template>
    <footer>
        <div>
            <LogoComponent @click="to('/')"/>
            <p>Copyright &copy; {{ year }} <span>avericar</span></p>
        </div>
        <div>
            <div class="menu">
                <p>Enlaces</p>
                <ul>
                    <li @click="to('/')">Inicio</li>
                    <li @click="to('/servicios')">Servicios</li>
                    <li @click="to('/como_funciona')">¿Cómo funciona?</li>
                    <li @click="to('/faqs')">FAQS</li>
                    <li @click="to('/contacto')">Contacto</li>
                    <li @click="to('/cita')">Pedir cita</li>
                </ul>
            </div>
            <div class="contacto">
                <p>Contacto</p>
                <ul>
                    <li><font-awesome-icon icon="envelope"/><a href="mailto:info@avericar.es">info@avericar.es</a></li>
                    <li><font-awesome-icon icon="phone"/>+34 727 733 356</li>
                    <li><font-awesome-icon icon="calendar-days"/>Horario de&nbsp;<span>lunes a viernes</span></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<style scoped>
footer {
    padding: 40px 70px;
    background-color: #ededed;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    font-weight: 500;
}
footer span {
    font-weight: bold;
}
footer > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
footer > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 100px;
}

/* MENU */
.menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.menu p {
    font-size: 18px !important;
    font-weight: 600;
}
.menu ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.menu ul li {
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.menu ul li:hover {
    color: #384a8a;
}

/* CONTACTO */
.contacto {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.contacto p {
    font-size: 18px !important;
    font-weight: 600;
}
.contacto ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.contacto ul li {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.contacto ul li svg {
    color: #384a8a;
    margin-right: 10px;
}
.contacto ul li a {
    all: unset;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.contacto ul li a:hover {
    color: #384a8a;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1100px) {
    footer {
        flex-direction: column;
    }
    footer > div:last-child {
        flex-direction: column;
        gap: 40px;
    }
}
@media screen and (max-width: 950px) {
    footer {
        align-items: flex-start;
    }
}
@media screen and (max-width: 800px) {
    footer {
        padding: 50px 25px;
    }
    .menu ul {
        gap: 5px;
    }
    .menu ul li {
        margin-right: 10px;
    }
}
</style>