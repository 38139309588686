<script>
import { scrollAnimation } from "./js/scrollUtils";

export default {
    name: "ComoFuncionaView",
    beforeMount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "white";
            nav.style.boxShadow = "0px 3px 5px 0px rgba(0, 0, 0, 0.1)";
        });
    },
    mounted() {
        document.title = "¿Cómo funciona? - Avericar";

        // Animaciones
        this.scrollAnimation({
            target: ".hero p",
            direction: "top",
            duration: 1000,
            distance: "50px"
        });

        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        if (!isMobile) { // PC
            this.scrollAnimation({
                target: ".como_funciona > div *:not(svg, svg *)",
                direction: "bottom",
                duration: 1000,
                distance: "50px"
            });
        } else { // MOBILE
            this.scrollAnimation({
                target: ".como_funciona > div:not(:nth-child(2)) *:not(svg, svg *)",
                direction: "right",
                duration: 1000,
                distance: "50px"
            });
            this.scrollAnimation({
                target: ".como_funciona > div:nth-child(2) *:not(svg, svg *)",
                direction: "left",
                duration: 1000,
                distance: "50px"
            });
        }
    },
    beforeUnmount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "revert-layer";
            nav.style.boxShadow = "unset";
        });
    },
    methods: {
        scrollAnimation,
    },
};
</script>

<template>
    <div class="hero">
        <div></div>
        <p>Nosotros te ayudamos</p>
        <p>¿CÓMO FUNCIONA?</p>
    </div>
    <div class="como_funciona">
        <div>
            <font-awesome-icon icon="phone"/>
            <p>Contáctanos</p>
            <img :src="require('@/assets/imgs/como_funciona/contacto.jpg')" />
            <p>
                No dudes en ponerte en contacto con nosotros de diversas maneras: a través de nuestro 
                <span @click="this.$router.push('/contacto')" style="cursor: pointer">formulario en línea</span>, mediante 
                <span>correo electrónico</span> o llamando a nuestro <span>número de teléfono</span> 
                móvil. Estamos aquí para atenderte con gusto y responder a todas tus preguntas, 
                aclarando lo que necesites.
            </p>
        </div>
        <div>
            <font-awesome-icon icon="user"/>
            <p>Nos desplazamos</p>
            <img :src="require('@/assets/imgs/como_funciona/desplazamos.jpg')" />
            <p>
                Un operario se desplazará cuidadosamente a la <span>ubicación que indique</span>, ya sea su domicilio 
                o lugar de trabajo, con el objetivo de llevar a cabo el <span>servicio solicitado</span>. Este 
                desplazamiento se realizará siempre garantizando así <span>planificación adecuada</span> y una 
                llegada puntual para cumplir con sus necesidades.
            </p>
        </div>
        <div>
            <font-awesome-icon icon="wrench"/>
            <p>Solventamos el problema</p>
            <img :src="require('@/assets/imgs/como_funciona/solventamos.jpg')" />
            <p>
                Cuando <span>diagnosticamos averías</span>, efectuamos un chequeo a través del <span>conector OBD</span>. En caso de 
                <span>clonación de llaves</span>, venimos preparados para <span>solucionarlo rápidamente</span>. Y si necesita pasar la 
                ITV, nos <span>encargamos de recoger y llevar</span> su vehículo a la estación correspondiente sin ningún 
                problema.
            </p>
        </div>
    </div>
</template>

<style scoped>
/* HERO */
.hero {
    position: relative;
    padding: 158px 50px 90px 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./assets/imgs/como_funciona/hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.hero div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}
.hero p:nth-child(2) {
    position: relative;
    font-weight: 500;
    color: white;
}
.hero p:last-child {
    position: relative;
    font-weight: bold;
    font-size: 55px !important;
    color: white;
}

/* COMO FUNCIONA */
.como_funciona {
    padding: 70px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}
.como_funciona::before {
    content: "";
    position: absolute;
    left: 0;
    top: 92px;
    width: 100%;
    height: 2px;
    background-color: #cacaca;
    z-index: -1;
}
.como_funciona > div {
    flex: 1;
    position: relative;
    padding: 0 15px 54px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 350px;
}
.como_funciona > div span {
    font-weight: 600;
    transition: all .2s ease-in-out;
}
.como_funciona > div span:hover {
    color: #384a8a;
}
.como_funciona > div > *:not(:first-child) {
    margin-left: 50px;
}
.como_funciona > div::before {
    content: "";
    position: absolute;
    left: 22px;
    width: 2px;
    height: 100%;
    background-color: #384a8a;
    z-index: -1;
}
.como_funciona > div::after {
    content: "";
    position: absolute;
    left: 22px;
    bottom: 0;
    width: 100%;
    height: 10px;
    background-color: #384a8a;
    z-index: -1;
}
.como_funciona > div svg {
    transform: translateX(-15px);
    padding: 15px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #384a8a;
    color: white;
}
.como_funciona > div p:nth-child(2) {
    font-weight: bold;
    font-size: 18px !important;
}
.como_funciona > div p:last-child {
    font-weight: 500;
}
.como_funciona > div img {
    max-width: 100%;
    height: 222px;
    object-fit: cover;
}

/* MEDIA QUERIES */
@media screen and (max-width: 800px) {
    .hero {
        padding: 168px 25px 100px 25px;
    }
    .hero p:nth-child(2) {
        margin-bottom: 8px;
    }
    .hero p:last-child {
        position: relative;
        font-weight: bold;
        font-size: 35px !important;
        color: white;
    }
    .como_funciona {
        padding: 50px 25px;
    }
    .como_funciona::before {
        top: 72px;
    }
}
@media screen and (max-width: 600px) {
    .como_funciona {
        padding: 50px 25px;
        flex-direction: column;
    }
    .como_funciona::before {
        all: unset;
    }
    .como_funciona > div {
        padding: 0;
        min-width: unset;
    }
    .como_funciona > div::before {
        height: 2px;
        width: calc(100% + 50px);
        top: 22px;
        left: -25px;
        background-color: #cacaca;
    }
    .como_funciona > div::after {
        all: unset;
    }
    .como_funciona > div *:not(:first-child) {
        margin-left: 0;
    }
    .como_funciona > div svg {
        transform: none;
    }
}
</style>