<script>
import { scrollAnimation } from "./js/scrollUtils";

export default {
    name: 'ContactoView',
    data() {
        return {
            whatsappLink: "https://wa.me/727733356",
            emailLink: "mailto:info@avericar.es"
        };
    },
    beforeMount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "white";
            nav.style.boxShadow = "0px 3px 5px 0px rgba(0, 0, 0, 0.1)";
        });
    },
    mounted() {
        document.title = 'Contacto - Avericar';

        // Animaciones
        this.scrollAnimation({
            target: ".hero p",
            direction: "top",
            duration: 1000,
            distance: "50px"
        });
        
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        if (!isMobile) { // PC
            this.scrollAnimation({
                target: ".info",
                direction: "left",
                duration: 1200,
                distance: "80px"
            });
            this.scrollAnimation({
                target: ".contacto > div > img",
                direction: "left",
                duration: 950,
                distance: "100px"
            });
        } else { // MOBILE
            this.scrollAnimation({
                target: ".info",
                direction: "top",
                duration: 1000,
                distance: "50px"
            });
        }
    },
    beforeUnmount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "revert-layer";
            nav.style.boxShadow = "unset";
        });
    },
    methods: {
        scrollAnimation
    },
};
</script>

<template>
    <div class="hero">
        <div></div>
        <p>Dinos que necesitas</p>
        <p>CONTACTO</p>
    </div>
    <div class="contacto">
        <div>
            <img src="./assets/imgs/como_funciona/hero.jpg"/>
            <div class="info">
                <p>Ponte en contacto</p>
                <p>
                    Estamos encantados de escucharte. Ya sea que quieras <span>pedir cita</span>, tengas preguntas o 
                    simplemente quieras saludarnos, estamos aquí para ti. No dudes en ponerte en 
                    contacto con nosotros.
                </p>
                <div class="horario">
                    <p>Horario</p>
                    Lunes - viernes de <span>9 a 19 horas</span>
                </div>
                <div class="metodos">
                    <a :href="whatsappLink" target="_blank" class="metodo">
                        <div style="background-color: #dcf5e1;"></div>
                        <font-awesome-icon :icon="['fab', 'whatsapp']" style="background-color: #2cd46b"/>
                        Contacta por WhatsApp
                    </a>
                    <a :href="emailLink" target="_blank" class="metodo">
                        <div style="background-color: #d3e7fb;"></div>
                        <font-awesome-icon icon="envelope" style="background-color: #4a86c7"/>
                        Contacta por E-mail
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* HERO */
.hero {
    position: relative;
    padding: 158px 50px 90px 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./assets/imgs/contacto/hero.jpg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}
.hero div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}
.hero p:nth-child(2) {
    position: relative;
    font-weight: 500;
    color: white;
}
.hero p:last-child {
    position: relative;
    font-weight: bold;
    font-size: 55px !important;
    color: white;
}

/* CONTACTO */
.contacto {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contacto > div {
    width: 100%;
    padding: 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
}
.contacto > div > img {
    filter: brightness(80%);
    object-fit: cover;
    object-position: left;
    width: 420px;
    height: 550px;
}

/* INFO */
.info {
    margin-top: 25px;
    max-width: fit-content;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 35px;
}
.info > p:first-child {
    font-weight: bold;
    font-size: 35px !important;
}
.info > p:nth-child(2) {
    min-width: 250px;
    max-width: 500px;
    font-weight: 500;
}
.info span {
    font-weight: 600;
}
.metodos {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.metodo {
    all: unset;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    cursor: pointer;
}
.metodo div {
    width: 0;
    position: absolute;
    transition: all .5s ease-in-out;
    z-index: -1;
    border-radius: 50px;
    height: 40px;
    left: 20px;
}
.metodo:hover div {
    width: calc(100% - 20px);
}
.metodo svg {
    padding: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: white;
}
.horario p {
    font-weight: bold;
    margin-bottom: 5px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1100px) {
    .contacto {
        height: unset;
    }
}
@media screen and (max-width: 900px) {
    .contacto > div {
        display: block;
    }
    .contacto > div > img {
        display: none;
    }
    .info {
        margin: 0;
    }
    .info > p:nth-child(2) {
        min-width: unset;
        max-width: unset;
    }
}
@media screen and (max-width: 800px) {
    .hero {
        padding: 168px 25px 100px 25px;
    }
    .hero p:nth-child(2) {
        margin-bottom: 8px;
    }
    .hero p:last-child {
        position: relative;
        font-weight: bold;
        font-size: 35px !important;
        color: white;
    }
    .contacto > div {
        padding: 50px 25px;
    }
}
</style>