<script>
import { scrollAnimation } from "./js/scrollUtils";

export default {
    name: "FAQSView",
    data() {
        return {
            openIndexes: []
        };
    },
    beforeMount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "white";
            nav.style.boxShadow = "0px 3px 5px 0px rgba(0, 0, 0, 0.1)";
        });
    },
    mounted() {
        document.title = "FAQS - Avericar";

        // Animaciones
        this.scrollAnimation({
            target: ".hero p",
            direction: "top",
            duration: 1000,
            distance: "50px"
        });

        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        if (!isMobile) { // PC
            // Left FAQS Animations
            let targets = document.getElementById("l_faqs");
            for (let i = 1; i <= targets.childElementCount; i++) {
                this.scrollAnimation({
                    target: `.faqs section:first-child .faq${i}`,
                    direction: "left",
                    duration: 800 + (200 * i),
                    distance: "80px"
                });
            }
            // Right FAQS Animations
            targets = document.getElementById("r_faqs");
            for (let i = 1; i <= targets.childElementCount; i++) {
                this.scrollAnimation({
                    target: `.faqs section:last-child .faq${i}`,
                    direction: "right",
                    duration: 800 + (200 * i),
                    distance: "80px"
                });
            }
        } else { // MOBILE
            // Left FAQS Animations
            let targets = document.getElementById("l_faqs");
            for (let i = 1; i <= targets.childElementCount; i++) {
                this.scrollAnimation({
                    target: `.faqs section:first-child .faq${i}`,
                    direction: i % 2 == 0 ? "left" : "right",
                    duration: 800 + (200 * i),
                    distance: "80px"
                });
            }
            // Right FAQS Animations
            targets = document.getElementById("r_faqs");
            for (let i = 1; i <= targets.childElementCount; i++) {
                this.scrollAnimation({
                    target: `.faqs section:last-child .faq${i}`,
                    direction: i % 2 == 0 ? "left" : "right",
                    duration: 800 + (200 * i),
                    distance: "80px"
                });
            }
        }
    },
    beforeUnmount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "revert-layer";
            nav.style.boxShadow = "unset";
        });
    },
    methods: {
        scrollAnimation,
        toggleFAQ(index) {
            if (this.openIndexes.includes(index)) {
                this.openIndexes = this.openIndexes.filter((i) => i !== index);
            } else {
                this.openIndexes.push(index);
            }
        }
    },
};
</script>

<template>
    <div class="hero">
        <div></div>
        <p>Preguntas frecuentes</p>
        <p>FAQS</p>
    </div>
    <div class="faqs">
        <section id="l_faqs">
            <div class="faq faq1">
                <button @click="toggleFAQ(1)">¿Cuál es el proceso para solicitar vuestros servicios de diagnóstico de averías?<font-awesome-icon :icon="openIndexes.includes(1) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(1)">
                    Llamar al teléfono o contactar por correo electrónico.
                </p>
            </div>
            <div class="faq faq2">
                <button @click="toggleFAQ(2)">¿Qué tipo de herramientas y equipos utilizáis para el diagnóstico de problemas en los vehículos?<font-awesome-icon :icon="openIndexes.includes(2) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(2)">
                    Equipos de gama alta actualizados a los últimos vehículos.
                </p>
            </div>
            <div class="faq faq3">
                <button @click="toggleFAQ(3)">¿Qué información necesitáis para clonar una llave de coche?<font-awesome-icon :icon="openIndexes.includes(3) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(3)">
                    El número de bastidor y la llave del vehículo.
                </p>
            </div>
            <div class="faq faq4">
                <button @click="toggleFAQ(4)">¿Ofrecéis servicio de clonación de llaves a domicilio?<font-awesome-icon :icon="openIndexes.includes(4) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(4)">
                    Sí. Un operario se acercaría a domicilio u oficina en la periferia de Granada.
                </p>
            </div>
            <div class="faq faq5">
                <button @click="toggleFAQ(5)">¿Cuánto tiempo suele tomar el proceso de clonación de llaves?<font-awesome-icon :icon="openIndexes.includes(5) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(5)">
                    Depende del vehículo.
                </p>
            </div>
            <div class="faq faq6">
                <button @click="toggleFAQ(6)">¿Cuál es el procedimiento para programar el ITV de mi coche a través de vuestra empresa?<font-awesome-icon :icon="openIndexes.includes(6) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(6)">
                    Deberás informar de cuándo caduca el ITV y un operario se encarga de llamar y pedir cita al ITV. Se recogerá el vehículo a domicilio u oficina y se entrega con el resultado final.
                </p>
            </div>
        </section>
        <section id="r_faqs">
            <div class="faq faq1">
                <button @click="toggleFAQ(7)">¿Puedo programar una cita para el ITV de mi vehículo con antelación?<font-awesome-icon :icon="openIndexes.includes(7) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(7)">
                    Sí, por supuesto.
                </p>
            </div>
            <div class="faq faq2">
                <button @click="toggleFAQ(8)">¿Ofrecéis servicio de recogida y entrega para el ITV de mi coche?<font-awesome-icon :icon="openIndexes.includes(8) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(8)">
                    Sí, el operario se desplazará a su domicilio u oficina en el entorno de Granada.
                </p>
            </div>
            <div class="faq faq3">
                <button @click="toggleFAQ(9)">¿Qué documentación necesito proporcionar para el ITV de mi vehículo?<font-awesome-icon :icon="openIndexes.includes(9) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(9)">
                    Ficha técnica y permiso de circulación.
                </p>
            </div>
            <div class="faq faq4">
                <button @click="toggleFAQ(10)">¿Ofrecéis asistencia para solucionar problemas que puedan surgir durante la inspección del ITV?<font-awesome-icon :icon="openIndexes.includes(10) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(10)">
                    Depende del tipo de problema. Si es borrar un código de avería o cambiar lámpara fundida, sí.
                </p>
            </div>
            <div class="faq faq5">
                <button @click="toggleFAQ(11)">¿Cuáles son vuestros horarios de trabajo para realizar estos servicios?<font-awesome-icon :icon="openIndexes.includes(11) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(11)">
                    El horario es de 09 a 19 horas de lunes a viernes.
                </p>
            </div>
            <div class="faq faq6">
                <button @click="toggleFAQ(12)">¿Ofrecéis servicio de emergencia para casos de averías repentinas?<font-awesome-icon :icon="openIndexes.includes(12) ? 'caret-up' : 'caret-down'"/></button>
                <p v-show="openIndexes.includes(12)">
                    Si el vehículo no arranca por un código de avería, se intentaría borrar dicho código.
                </p>
            </div>
        </section>
    </div>
</template>

<style scoped>
/* HERO */
.hero {
    position: relative;
    padding: 158px 50px 90px 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./assets/imgs/faqs/hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.hero div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}
.hero p:nth-child(2) {
    position: relative;
    font-weight: 500;
    color: white;
}
.hero p:last-child {
    position: relative;
    font-weight: bold;
    font-size: 55px !important;
    color: white;
}

/* FAQS */
.faqs {
    padding: 70px;
    display: flex;
    justify-content: center;
    gap: 50px;
}
.faqs section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.faq {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.faq button {
    text-align: left;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    cursor: pointer;
    padding: 20px 20px;
    background-color: transparent;
    border: 1px solid #cacaca;
    border-left: 10px solid #384a8a;
    font-weight: 500;
}
.faq p {
    padding: 5px 30px;
    font-weight: 500;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1100px) {
    .faqs {
        flex-direction: column;
    }
}
@media screen and (max-width: 800px) {
    .hero {
        padding: 168px 25px 100px 25px;
    }
    .hero p:nth-child(2) {
        margin-bottom: 8px;
    }
    .hero p:last-child {
        position: relative;
        font-weight: bold;
        font-size: 35px !important;
        color: white;
    }
    .faqs {
        padding: 50px 0;
        gap: 0;
    }
    .faqs section {
        gap: 0;
    }
    .faq1 button {
        border-top: 1px solid #cacaca !important;
    }
    .faq button {
        gap: 15px;
        font-weight: 500;
        font-size: 14px !important;
        color: #384a8a;
        border: none;
        border-bottom: 1px solid #cacaca;
    }
    .faq p {
        font-size: 14px !important;
        padding: 5px 20px;
    }
}
</style>