<script>
import LogoComponent from "@/components/LogoComponent.vue";

export default {
    name: "NavComponent",
    data() {
        return {
            menu: null,
            startX: 0,
            screenWidth: window.innerWidth,
            scrolled: window.scrollY > 100,
        };
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
        document.addEventListener("touchstart", this.handleTouchStart);
        document.addEventListener("touchmove", this.handleTouchMove);
        window.addEventListener("resize", this.updateScreenWidth);
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeMenu);
        document.removeEventListener("touchstart", this.handleTouchStart);
        document.removeEventListener("touchmove", this.handleTouchMove);
        window.removeEventListener("resize", this.updateScreenWidth);
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        to(path, e) {
            this.menu = false;
            if (e && path !== "/") {
                document.querySelectorAll(".nav ul li").forEach((item) => {
                    item.classList.remove("active");
                });
                e.target.classList.add("active");
            }
            this.$router.push(path);
        },
        closeMenu(e) {
            if (this.menu && !this.$refs.navMobile.contains(e.target)) {
                this.menu = false;
            }
        },
        handleTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        handleTouchMove(e) {
            let currentX = e.touches[0].clientX;
            let diffX = currentX - this.startX;

            if (diffX < -50) {
                this.menu = true;
            } else if (diffX > 50) {
                this.menu = false;
            }
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        handleScroll() {
            this.scrolled = window.scrollY > 100;
        },
    },
    components: { LogoComponent },
};
</script>

<template>
    <div class="nav" :class="scrolled ? 'scrolled' : ''" id="nav">
        <ul>
            <li :class="{ active: $route.path === '/' }" @click="to('/', $event)">Inicio</li>
            <li :class="{ active: $route.path === '/servicios' }" @click="to('/servicios', $event)">Servicios</li>
            <li :class="{ active: $route.path === '/como_funciona' }" @click="to('/como_funciona', $event)">¿Cómo funciona?</li>
        </ul>
        <LogoComponent @click="to('/', $event)"/>
        <ul>
            <li :class="{ active: $route.path === '/faqs' }" @click="to('/faqs', $event)">FAQS</li>
            <li :class="{ active: $route.path === '/contacto' }" @click="to('/contacto', $event)">Contacto</li>
            <li :class="{ active: $route.path === '/cita' }" @click="to('/cita', $event)" class='cita'>Pedir cita</li>
        </ul>
    </div>
    <div class="nav_mobile" :class="scrolled ? 'scrolled' : ''" ref="navMobile" id="nav">
        <LogoComponent @click="to('/', $event)"/>
        <font-awesome-icon icon="bars" @click="menu = !menu"/>
        <ul :class="menu ? 'active' : ''">
            <li :class="{ active: $route.path === '/' }" @click="to('/', $event)">Inicio</li>
            <li :class="{ active: $route.path === '/servicios' }" @click="to('/servicios', $event)">Servicios</li>
            <li :class="{ active: $route.path === '/como_funciona' }" @click="to('/como_funciona', $event)">¿Cómo funciona?</li>
            <li :class="{ active: $route.path === '/faqs' }" @click="to('/faqs', $event)">FAQS</li>
            <li :class="{ active: $route.path === '/contacto' }" @click="to('/contacto', $event)">Contacto</li>
            <li :class="{ active: $route.path === '/cita' }" @click="to('/cita', $event)" class='cita'>Pedir cita</li>
            <font-awesome-icon icon="xmark" @click="menu = !menu"/>
        </ul>
    </div>
</template>

<style scoped>
/* NAV */
.nav {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 35px;
    gap: 50px;
}
.nav > svg {
    transform: translateX(-6px);
}
.nav ul {
    display: flex;
    align-items: center;
    gap: 35px;
}
.nav ul li {
    cursor: pointer;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}
.nav ul li:hover {
    color: #384a8a;
}
.nav ul li:not(.cita).active {
    position: relative;
    color: #384a8a;
}
.nav ul li:not(.cita)::after {
    content: "";
    width: 0;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
}
.nav ul li:not(.cita).active::after {
    content: "";
    cursor: default;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: calc(100% + 12px);
    height: 2px;
    background-color: #384a8a;
}
.cita {
    background-color: #384a8a;
    color: white !important;
    padding: 8px 18px 8px 20px;
    transition: all .3s ease-in-out !important;
    box-sizing: border-box;
    border: 3px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
}
.cita:hover {
    background-color: white;
    color: black !important;
    border: 3px solid #384a8a;
    border-right: 5px #384a8a solid;
    border-bottom: 5px #384a8a solid;
}
.cita.active {
    background-color: white;
    color: black !important;
    border: 3px solid #384a8a;
    border-right: 5px #384a8a solid;
    border-bottom: 5px #384a8a solid;
}
.nav,
.nav_mobile {
    transition: background-color 0.2s ease-in-out;
}
.nav.scrolled,
.nav_mobile.scrolled {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) !important;
    background-color: white;
}

/* NAV MOBILE */
.nav_mobile {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 16px 25px;
}
.nav_mobile > svg {
    cursor: pointer;
    font-size: 20px !important;
    transition: all 0.2s ease-in-out;
}
.nav_mobile > svg:hover {
    color: #384a8a;
}
.nav_mobile ul {
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 0;
    right: -100%;
    background-color: #384a8a;
    padding: 35px 30px;
    width: 100%;
    min-width: 220px;
    transition: right 0.5s ease-in-out;
}
.nav_mobile ul.active {
    right: 0;
}
.nav_mobile ul li:not(.cita) {
    width: fit-content;
    font-weight: 600;
    cursor: pointer !important;
    color: white !important;
    transition: all 0.2s ease-in-out;
    border-left: 2px solid transparent;
}
.nav_mobile ul li:hover {
    border-left: 2px solid white;
    padding-left: 10px;
}
.nav_mobile ul li.active {
    border-left: 2px solid white;
    padding-left: 10px;
}
.nav_mobile ul li.cita {
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    background-color: white;
    color: black !important;
    padding: 5px 16px 5px 18px !important;
}
.nav_mobile ul li.cita:hover {
    border: 3px solid black;
    border-right: 5px black solid;
    border-bottom: 5px black solid;
}
.nav_mobile ul li.cita.active {
    border: 3px solid black;
    border-right: 5px black solid;
    border-bottom: 5px black solid;
}
.nav_mobile ul svg {
    position: absolute;
    top: 22px;
    right: 26px;
    cursor: pointer;
    font-size: 20px !important;
    transition: all 0.2s ease-in-out;
    color: white !important;
}
.nav_mobile ul svg:hover {
    color: rgb(190, 190, 190) !important;
}

/* MEDIA QUERIES */
@media screen and (max-width: 950px) {
    .nav {
        display: none;
    }
    .nav_mobile {
        display: flex;
    }
}
@media screen and (max-width: 600px) {
    .nav_mobile ul {
        padding: 25px 20px;
        min-width: 210px;
    }
}
</style>