<script>
import { scrollAnimation } from "./js/scrollUtils";

export default {
    name: "ServiciosView",
    beforeMount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "white";
            nav.style.boxShadow = "0px 3px 5px 0px rgba(0, 0, 0, 0.1)";
        });
    },
    mounted() {
        document.title = "Servicios - Avericar";

        // Animaciones
        this.scrollAnimation({
            target: ".hero p",
            direction: "top",
            duration: 1000,
            distance: "50px"
        });

        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        if (!isMobile) { // PC
            this.scrollAnimation({
                target: ".servicios > div > div > div",
                direction: "right",
                duration: 1200,
                distance: "80px"
            });
        } else { // MOBILE
            this.scrollAnimation({
                target: ".servicios > div > div:first-child",
                direction: "right",
                duration: 1200,
                distance: "80px"
            });
            this.scrollAnimation({
                target: ".servicios > div > div:nth-child(2)",
                direction: "left",
                duration: 1200,
                distance: "80px"
            });
        }
    },
    beforeUnmount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            nav.style.transition = "unset";
            nav.style.backgroundColor = "revert-layer";
            nav.style.boxShadow = "unset";
        });
    },
    methods: {
        scrollAnimation,
    },
};
</script>

<template>
    <div class="hero">
        <div></div>
        <p>¿Qué ofrecemos?</p>
        <p>SERVICIOS</p>
    </div>
    <div class="servicios">
        <div>
            <div id="diagnostico">
                <font-awesome-icon icon="car-battery"/>
                <div>
                    <p>Primer servicio</p>
                    <p>Diagnóstico de averías</p>
                    <p>
                        Con el sistema de diagnóstico Autel, se podrá reconocer muchos de los códigos de error directamente. 
                        La documentación sobre los códigos de error según los diferentes sistemas y versiones de diagnóstico 
                        ayudará a detectar rápidamente el fallo. Podrá disponer en todo momento de los códigos de error y 
                        eliminar los mismos siempre que no persista un fallo en el motor.
                    </p>
                </div>
            </div>
            <div id="clonacion">
                <font-awesome-icon icon="key"/>
                <div>
                    <p>Segundo servicio</p>
                    <p>Clonación de llaves</p>
                    <p>
                        El proceso de clonación se describe en estos procesos:
                        <ol>
                            <li>Lectura de llave original para obtención de los datos del 'transponder'.</li>
                            <li>Tras la obtención de los datos de la llave mando, se procede a transferir los mismos a un nuevo mando.</li>
                        </ol>
                    </p>
                </div>
            </div>
            <div id="itv">
                <font-awesome-icon icon="car-side"/>
                <div>
                    <p>Tercer servicio</p>
                    <p>ITV</p>
                    <p>
                        Nuestro servicio de ITV a domicilio funciona en Granada 
                        y periferia. Un operario se personará en el lugar que nos 
                        indique para recoger su vehículo. Si tienes un ciclomotor 
                        o motocicleta también te daremos este servicio.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* HERO */
.hero {
    position: relative;
    padding: 158px 50px 90px 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./assets/imgs/servicios/hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.hero div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}
.hero p:nth-child(2) {
    position: relative;
    font-weight: 500;
    color: white;
}
.hero p:last-child {
    position: relative;
    font-weight: bold;
    font-size: 55px !important;
    color: white;
}

/* SERVICIOS */
.servicios {
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.servicios > div {
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.servicios > div::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #cacaca;
    left: 22px;
}
.servicios > div > div {
    max-width: 950px;
    display: flex;
    gap: 35px;
}
.servicios > div > div svg {
    padding: 15px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #384a8a;
    color: white;
    transform: translateY(25px);
}
.servicios > div > div > div {
    position: relative;
    border: 1px solid #cacaca;
    padding: 25px 40px;
}
.servicios > div > div > div::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 32px;
    right: auto;
    border-bottom: 15px solid transparent;
    border-left: 0 solid #929292;
    border-right: 15px solid #929292;
    border-top: 15px solid transparent;
}
.servicios > div > div > div::after {
    content: "";
    position: absolute;
    left: -14px;
    top: 33px;
    right: auto;
    border-bottom: 14px solid transparent;
    border-left: 0 solid white;
    border-right: 14px solid white;
    border-top: 14px solid transparent;
}
.servicios > div > div > div p:nth-child(1) {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px !important;
    color: #384a8a;
}
.servicios > div > div > div p:nth-child(2) {
    font-size: 18px !important;
    font-weight: 600;
}
.servicios > div > div > div p:last-child {
    margin-top: 10px;
    font-weight: 500;
}
.servicios > div > div > div ol {
    margin-top: 7px; 
    margin-left: 17px;
    list-style:disc !important;
}

/* MEDIA QUERIES */
@media screen and (max-width: 800px) {
    .hero {
        padding: 168px 25px 100px 25px;
    }
    .hero p:nth-child(2) {
        margin-bottom: 8px;
    }
    .hero p:last-child {
        position: relative;
        font-weight: bold;
        font-size: 35px !important;
        color: white;
    }
    .servicios {
        padding: 50px 25px;
    }
    .servicios > div::before {
        all: unset;
    }
}
@media screen and (max-width: 600px) {
    .servicios {
        padding: 0;
    }
    .servicios > div {
        gap: 0;
    }
    .servicios > div > div {
        flex-direction: column;
        gap: 15px;
        padding: 50px 25px;
    }
    .servicios > div > div:not(:last-child) {
        border-bottom: 2px solid #384a8a;
    }
    .servicios > div > div svg {
        transform: none;
    }
    .servicios > div > div > div {
        padding: unset;
        border: none;
    }
    .servicios > div > div > div::before, .servicios > div > div > div::after {
        all: unset;
    }
}
</style>