<script>
export default {
    name: 'CitaView',
    mounted() {
        document.title = 'Cita - Avericar';
    },
};
</script>

<template>
    <div>
        <p>Pedir cita</p>
        <p>PRONTO</p>
        <p>
            Pronto tendremos la opción para poder pedir cita con el manejo de un calendario. 
            De momento, la única forma de pedir cita es enviando un mensaje en la página de 
            <span @click="this.$router.push('/contacto')">contacto</span>, por correo electrónico o por llamada.
        </p>
    </div>
</template>

<style scoped>
div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}
div p:first-child {
    font-weight: 600;
    color: #384a8a;
}
div p:nth-child(2) {
    font-weight: bold;
    font-size: 55px !important;
}
div p:last-child {
    margin-top: 5px;
    max-width: 700px;
    text-align: center;
    font-weight: 500;
}
div span {
    cursor: pointer;
    font-weight: bold;
    transition: all .2s ease-in-out;
}
div span:hover {
    color: #384a8a;
}

@media screen and (max-width: 600px) {
    div p:nth-child(2) {
        font-size: 40px !important;
    }
}
</style>