<script>
import { scrollAnimation } from "./js/scrollUtils";

export default {
    name: "InicioView",
    data() {
        return {
            isMobile: window.matchMedia("(max-width: 768px)").matches
        }
    },
    beforeMount() {
        document.querySelectorAll("#nav").forEach((nav) => {
            setTimeout(() => {
                nav.style.transition = "background-color 0.2s ease-in-out";
            }, 200)
        });
    },
    mounted() {
        document.title = "Inicio - Avericar";

        // Animaciones
        if (!this.isMobile) { // PC
            this.scrollAnimation({
                target: ".intro > div:nth-child(2)",
                direction: "top",
                duration: 1000,
                distance: "50px"
            });
            this.scrollAnimation({
                target: ".intro > div:nth-child(1), .intro > div:nth-child(3)",
                direction: "bottom",
                duration: 1000,
                distance: "50px"
            });
            this.scrollAnimation({
                target: ".hero > div",
                direction: "left",
                duration: 1200,
                distance: "80px"
            });
        } else { // MOBILE
            this.scrollAnimation({
                target: ".intro > div:nth-child(2)",
                direction: "right",
                duration: 1200,
                distance: "80px"
            });
            this.scrollAnimation({
                target: `
                    .hero > div,
                    .intro > div:nth-child(1), .intro > div:nth-child(3)
                `,
                direction: "left",
                duration: 1200,
                distance: "80px"
            });
        }
    },
    methods: {
        scrollAnimation,
        goTo(target) {
            this.$router.push("/servicios");

            if (this.isMobile) {
                setTimeout(() => {
                    const element = document.getElementById(target);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 1200);
            }
        }
    },
};
</script>

<template>
    <div class="hero">
        <div>
            <p><span>Descubre lo que ofrecemos</span>Encuentra la mejor solución para tu vehículo</p>
            <p>¿Te gustaría que nos ocuparamos de todos los detalles de tu vehículo por ti? Pide <span @click="this.$router.push('/cita')" style="color: #384A8A">cita</span> o <span @click="this.$router.push('/contacto')" style="color: #384A8A">contáctanos</span></p>
            <div class="hero_servicios">
                <p>Servicios ofrecidos:</p>
                <div>
                    <div @click="goTo('diagnostico')"><font-awesome-icon icon="car-battery" /><p>Diagnóstico averías</p></div>
                    <div @click="goTo('clonacion')"><font-awesome-icon icon="key" /><p>Clonación llaves</p></div>
                    <div @click="goTo('itv')"><font-awesome-icon icon="car-side" /><p>ITV</p></div>
                </div>
            </div>
        </div>
    </div>
    <div class="intro">
        <div>
            <p><font-awesome-icon icon="toolbox" />Nuestro material</p>
            <img src="./assets/imgs/inicio/material.jpg"/>
            <p>Nos esforzamos por ofreceros el <span style="font-weight: 600">mejor servicio</span> en toda situación. Contamos con máquinas de diagnóstico <span style="font-weight: 600">profesional</span> y <span style="font-weight: 600">herramientas de calidad</span> para <span style="font-weight: 600">resolver</span> vuestras necesidades.</p>
        </div>
        <div>
            <p><font-awesome-icon icon="handshake" />Proceso de cita</p>
            <img src="./assets/imgs/inicio/cita.jpg"/>
            <p><span style="font-weight: 600">Elige el día y el lugar</span> que te convengan y <span style="font-weight: 600">nos encargaremos de desplazarnos</span> hasta donde estés para <span style="font-weight: 600">realizar la revisión u otro servicio</span> que hayas pedido previamente.</p>
        </div>
        <div>
            <p><font-awesome-icon icon="medal" />Calidad garantizada</p>
            <img src="./assets/imgs/inicio/calidad.jpg"/>
            <p>Nos comprometemos a brindarte la <span style="font-weight: 600">máxima calidad</span> en cada servicio que ofrecemos. Tu <span style="font-weight: 600">satisfacción</span> siempre será <span style="font-weight: 600">nuestra prioridad absoluta</span> y por ello estaremos siempre cuando lo necesites.</p>
        </div>
    </div>
</template>

<style scoped>
/* HERO */
.hero {
    background-image: url(./assets/imgs/inicio/hero.jpeg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100%;
    height: 100vh;
    width: 100%;
}
.hero > div {
    z-index: 0;
    position: absolute;
    top: 25%;
    padding: 0 12%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.hero > div > p:first-child {
    max-width: 600px;
    font-size: 45px !important;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.hero > div > p:first-child span {
    color: #384a8a;
    border-left: 2px solid #384a8a;
    padding-left: 10px;
}
.hero > div > p:nth-child(2) {
    max-width: 600px;
    font-size: 18px !important;
    font-weight: 500;
}
.hero > div > p:nth-child(2) span {
    cursor: pointer;
    max-width: 600px;
    font-size: 18px !important;
    font-weight: 600;
    transition: color 0.1s ease-in-out;
}
.hero > div > p:nth-child(2) span:hover {
    color: black !important;
}
.hero_servicios {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.hero_servicios > p {
    font-weight: 600;
}
.hero_servicios div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.hero_servicios div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.hero_servicios > div > div {
    background-color: #ffffffd4;
    padding: 10px 18px;
    border-radius: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.1s ease-in-out;
}
.hero_servicios > div > div:hover {
    background-color: #ffffffd4;
}
.hero_servicios > div > div * {
    font-size: 13px !important;
}

/* INTRODUCTION */
.intro {
    overflow: hidden;
    padding: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
}
.intro div {
    position: relative;
    min-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 5px;
    font-weight: 500;
}
.intro div p:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px !important;
    font-weight: 600;
}
.intro svg {
    color: #384a8a;
    font-size: 20px !important;
}
.intro img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
}

@media screen and (max-width: 950px) {
    .hero {
        background-position-x: 80%;
        background-attachment: unset;
    }
    .hero > div {
        top: 16%;
    }
    .hero > div > p:first-child {
        font-size: 35px !important;
    }
    .hero > div > p:nth-child(2) {
        max-width: 600px;
        font-size: 15px !important;
        font-weight: 500;
    }
    .hero > div > p:nth-child(2) span[data-v-42f0933a] {
        font-size: 15px !important;
    }
    .scroll-downs {
        display: none;
    }
}
@media screen and (max-width: 800px) {
    .hero > div {
        padding: 0 25px;
    }
    .hero_servicios > div > div p {
        display: none;
    }
    .intro {
        padding: 50px 25px;
    }
}
@media screen and (max-width: 600px) {
    .intro {
        flex-direction: column;
    }
    .intro div {
        min-width: unset;
    }
}
</style>